<template v-if="formActive">
    <FormItem id="name" :label="$t('overview.name')" v-model="surchargetype.name" sizeLabel="2" sizeItem="5" :required="true" />
    <FormItem id="code" :label="$t('overview.code')" v-model="surchargetype.code" sizeLabel="2" sizeItem="5" :required="true" />
    <br />
    <div class="row">
        <div class="col-2"></div>
        <div class="col-5">
            <FormItem id="opt1" type="checkbox" :label="$t('form.optional')" v-model="surchargetype.optional" :disabled="surchargetype.has_measurement_conditionals" />
            <FormItem id="opt2" type="checkbox" :label="$t('masterdata.m_conditionials')" v-model="surchargetype.has_measurement_conditionals" :disabled="surchargetype.optional" />
        </div>
        <div class="col-5" v-if="surchargetype.has_measurement_conditionals">
            <br />
            <FormItem id="opt3" type="select" :label="$t('masterdata.based_on')" v-model="surchargetype.measurement_conditional_id" :options="measurementConditionals" :required="true" />
        </div>
        <div class="col-5" v-else-if="surchargetype.optional">
            <FormItem id="opt4" :label="$t('form.label')" v-model="surchargetype.label" :required="true" />
        </div>
    </div>
                        
</template>

<script>
    import dataStore from '@/store/data';
    export default {
        name: 'SurchargeTypeForm',
        props: ['itemData'],
        data() {
            return {
                surchargetype: {},
                measurementConditionals: [],
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.surchargetype = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                this.invalid = {};
                let valid = this.surchargetype.name && this.surchargetype.code;
                if(valid && this.surchargetype.has_measurement_conditionals){
                    valid = this.surchargetype.measurement_conditional_id;
                }
                return valid;
            },
            getData() {
                return this.surchargetype;
            },
            setData(data) {
                this.surchargetype = data
            },
            reset() {
                this.surchargetype = {
                    'name': '',
                    'code': '',
                    'label': '',
                    'optional': 0,
                    'has_measurement_conditionals': 0,
                    'measurement_conditional_id': null
                }
                this.loading = false
                this.invalid = {}
            }
        },
        mounted(){
            this.measurementConditionals = dataStore.getters.getMeasurementConditionals;
        }
    }
</script>